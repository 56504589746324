import Swiper, { Pagination } from 'swiper';
import { debounce } from 'lodash';

class Usp {
    constructor() {
        this.swiper = null;
        this.setup();
    }

    swiperInit() {
        Swiper.use(Pagination);
        this.swiper = new Swiper('.usp__container', {
            loop: true,
            slidesPerView: 'auto',
            speed: 1000,
            pagination: {
                el: '.usp__pagination',
            }
        });

        this.pagination = document.querySelector('.usp__pagination');

        if (!this.pagination) {
            return;
        }

        this.paginationBullets = this.pagination.querySelectorAll('.swiper-pagination-bullet');

        for (let i = 0; i < this.paginationBullets.length; i++) {
            this.paginationBullets[i].addEventListener('click', this.goToSlide.bind(this, i));
        }
    }

    swiperDestroy() {
        this.swiper.destroy();
        this.swiper = null;
    }

    goToSlide(index) {
        this.swiper.slideTo(index);
    }

    resizeEvent() {
        if (window.innerWidth < 1024) {
            if (this.swiper) {
                return
            }

            this.swiperInit();
            return;
        }

        if (!this.swiper) {
            return;
        }

        this.swiperDestroy();
    }

    eventListeners() {
        window.addEventListener('resize', debounce(this.resizeEvent.bind(this), 150));

    }

    setup() {
        this.eventListeners();
        this.resizeEvent();
    }
}

const uspExists = document.querySelector('.usp-holder') || null;
if (uspExists) {
    new Usp();
}
