import Swiper, { Pagination } from 'swiper';
import { debounce } from 'lodash';

class Testimonials {
    constructor() {
        this.swiper = null;
        this.setup();
    }

    swiperInit() {
        Swiper.use(Pagination);
        this.swiper = new Swiper('.testimonials__container', {
            loop: true,
            slidesPerView: 'auto',
            speed: 1000,
            pagination: {
                el: '.testimonials__pagination',
            }
        });

        this.pagination = document.querySelector('.testimonials__pagination');

        if (!this.pagination) {
            return;
        }

        this.paginationBullets = this.pagination.querySelectorAll('.swiper-pagination-bullet');

        for (let i = 0; i < this.paginationBullets.length; i++) {
            this.paginationBullets[i].addEventListener('click', this.goToSlide.bind(this, i));
        }
    }

    swiperDestroy() {
        this.swiper.destroy();
        this.swiper = null;
    }

    goToSlide(index) {
        this.swiper.slideTo(index);
    }

    setup() {
        this.swiperInit();
    }
}

const testimonialsExists = document.querySelector('.testimonials-holder') || null;
if (testimonialsExists) {
    new Testimonials();
}
