import './index.scss'

import ClipboardJS from 'clipboard';
import zenscroll from 'zenscroll'
import Rellax from 'rellax';

// const offset = window.innerWidth < 768 ? 0 : 120
// zenscroll.setup(null, offset)

import './js/accordion';
import './js/cards';
import './js/header';
import './js/navigation';
import './js/projects';
import './js/steps';
import './js/testimonials';
import './js/usp';

const copyButton = document.querySelector('.title-block__meta-share-copy') || null;
if (copyButton) {
    new ClipboardJS('.title-block__meta-share-copy');
}

const availableColors = [
    'rgba(72, 120, 187, 1)',
    'rgba(231, 55, 49, 1)',
    'rgba(79, 152, 52, 1)',
    'rgba(252, 210, 161, 1)',
    'rgba(148, 97, 49, 1)',
    'rgba(251, 185, 0, 1)',
    'rgba(72, 120, 187, 0.2)',
    'rgba(231, 55, 49, 0.2)',
    'rgba(79, 152, 52, 0.2)',
    'rgba(251, 185, 0, 0.2)'
];

const allShapes = document.querySelectorAll('.random-shape');
if (allShapes) {
    for (let i = 0; i < allShapes.length; i++) {
        const path = allShapes[i].querySelector('path');
        const randomColor = availableColors[Math.floor(Math.random() * availableColors.length)];
        path.style.fill = `${randomColor}`;
    }
}

const anchorLink = document.querySelector('.image-text-block__cta--anchor');
const sections = document.querySelectorAll('section');

if (anchorLink) {
    let section = sections[1].getAttribute('id');
    if (section !== 'usps') {
        anchorLink.setAttribute('href', `#${section}`);
    }

    if (section === 'usps') {
        section = sections[2].getAttribute('id');
        anchorLink.setAttribute('href', `#${section}`);
    }
}

new Rellax('.rellax');

const color = 'color: purple;'
const bold = 'font-weight: bold; font-size: 13px;'

const credits = `
%c███╗░░░███╗░█████╗░██████╗░███████╗
████╗░████║██╔══██╗██╔══██╗██╔════╝
██╔████╔██║███████║██║░░██║█████╗░░
██║╚██╔╝██║██╔══██║██║░░██║██╔══╝░░
██║░╚═╝░██║██║░░██║██████╔╝███████╗
╚═╝░░░░░╚═╝╚═╝░░╚═╝╚═════╝░╚══════╝

██████╗░██╗░░░██╗██╗
██╔══██╗╚██╗░██╔╝╚═╝
██████╦╝░╚████╔╝░░░░
██╔══██╗░░╚██╔╝░░░░░
██████╦╝░░░██║░░░██╗
╚═════╝░░░░╚═╝░░░╚═╝
`;
// Credit: https://fsymbols.com/text-art/

if (process.env.NODE_ENV !== 'development') {
    console.log(credits, color)
    console.log('%chttps://www.developr-lab.nl', bold)
}
