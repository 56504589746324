import { gsap } from 'gsap';

class Accordion {
    constructor() {
        this.accordionList = document.querySelector('.text-accordion-block__accordion-list');
        this.accordionItems = document.querySelectorAll('.text-accordion-block__accordion-item');
        this.activeItem = null;
        this.isAnimating = false;
        this.setup();
    }

    eventListeners() {
        for (let i = 0; i < this.accordionItems.length; i++) {
            this.accordionItems[i].addEventListener('click', this.clickHandler.bind(this, this.accordionItems[i]));
        }
    }

    clickHandler(item) {
        if (this.isAnimating) {
            return;
        }

        this.isAnimating = true;

        if (this.activeItem === item) {
            this.handleClose(this.activeItem);
            return;
        }

        const body = item.querySelector('.text-accordion-block__accordion-body');
        const arrow = item.querySelector('.text-accordion-block__accordion-arrow');

        if (this.activeItem) {
            const previousBody = this.activeItem.querySelector('.text-accordion-block__accordion-body');
            const previousArrow = this.activeItem.querySelector('.text-accordion-block__accordion-arrow');

            gsap.to(previousBody, 0.8, {
                height: 0,
                ease: 'power4.out'
            });

            gsap.to(previousArrow, 0.8, {
                rotate: 0,
                ease: 'power4.out'
            });
        }

        gsap.to(body, 0.8, {
            height: 'auto',
            ease: 'power4.out'
        });

        gsap.to(arrow, 0.8, {
            rotate: -180,
            ease: 'power4.out',
            onComplete: () => {
                this.isAnimating = false;
            }
        });

        this.activeItem = item;
    }

    handleClose(item) {
        const previousBody = this.activeItem.querySelector('.text-accordion-block__accordion-body');
        const previousArrow = this.activeItem.querySelector('.text-accordion-block__accordion-arrow');

        gsap.to(previousBody, 0.8, {
            height: 0,
            ease: 'power4.out'
        });

        gsap.to(previousArrow, 0.8, {
            rotate: 0,
            ease: 'power4.out',
            onComplete: () => {
                this.isAnimating = false;
            }
        });

        this.activeItem = null;
    }

    setup() {
        this.eventListeners();
    }
}

const accordionExists = document.querySelector('.text-accordion-block-holder') || null;
if (accordionExists) {
    new Accordion();
}
