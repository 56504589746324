class Navigation {
    constructor() {
        this.navigation = document.querySelector('.navigation-holder');
        this.navButton = this.navigation.querySelector('.navigation__button-holder');
        this.navigationList = this.navigation.querySelector('.navigation__list-holder');
        this.isActive = false;
        this.setup();
    }

    toggleMenu() {
        if (!this.isActive) {
            this.isActive = true;
            this.navigationList.classList.add('active');
            return;
        }

        this.isActive = false;
        this.navigationList.classList.remove('active');
    }

    eventListener() {
        this.navButton.addEventListener('click', this.toggleMenu.bind(this));
    }

    setup() {
        this.eventListener();
    }
}

new Navigation();