import Headroom from 'headroom.js';

class Header {
    constructor() {
        this.header = document.querySelector('header');
        this.headroom  = new Headroom(this.header);
        this.headroom.init();
    }
}

new Header();
